// CustodyForm.tsx
// @author Diego Jurado Gerardino (XFMH5JR)
import { Form } from "@formio/react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import custodyForm from "../../assets/custody-form.json";
import Popup from "../Popup/Popup";
import { createAccount } from "../../services/CustodyAccountService";
import { CustodyResponse } from "../../types/CustodyResponse";
import { isSuccessfulResponse } from "../../utils/isSuccessfulResponse";

import "./custody-form.css";

function CustodyForm() {

  const [visibility, setVisibility] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleFormChange = () => {
    // TODO: implement - auto-populate form on account number input
  };

  const handleFormSubmission = async (submission) => {

    const response: CustodyResponse = await createAccount(submission.data);

    // TODO: implement - display spinner while waiting

    setTitle(isSuccessfulResponse(response) ? "Success" : "Failure");
    setMessage(response.metadata.description);
    setVisibility(true);

  };

  const popupCloseHandler = () => {
    setVisibility(false);
  }

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="page-headers">Custody Account Form</h2>
          <div className="main">
            <Form form={custodyForm} onChange={handleFormChange} onSubmit={handleFormSubmission} />
          </div>
        </Col>
      </Row>
      <Popup onClose={popupCloseHandler} show={visibility} title={title}>
        <h2>{message}</h2>
      </Popup>
    </Container>
  );

};

export default CustodyForm;
