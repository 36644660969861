
import { Home } from "./pages/Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";


 
function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/gsp-account-adapter-frm" element={<Home />} />
                </Routes>
            </Router>
        </div>

    )
}

export default App;