// Home.tsx
// @author Diego Jurado Gerardino (XFMH5JR)

import CustodyForm from "../components/CustodyForm/CustodyForm";

export function Home() {
    return (
        <CustodyForm />
    );
}

