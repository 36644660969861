// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
  }
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #000;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px) {
    .popup {
      width: 70%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Popup/popup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,8BAA8B;IAC9B,yBAAyB;EAC3B;EACA;IACE,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,WAAW;IACX,sCAAsC;EACxC;EACA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,WAAW;EACb;EACA;IACE,eAAe;IACf,WAAW;EACb;EACA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE;MACE,UAAU;IACZ;EACF","sourcesContent":[".overlay {\n    visibility: hidden;\n    opacity: 0;\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.7);\n    transition: opacity 500ms;\n  }\n  .popup {\n    margin: 70px auto;\n    padding: 20px;\n    background: #fff;\n    border-radius: 5px;\n    width: 30%;\n    position: relative;\n    transition: all 5s ease-in-out;\n  }\n  \n  .popup h2 {\n    margin-top: 0;\n    color: #333;\n    font-family: Tahoma, Arial, sans-serif;\n  }\n  .popup .close {\n    position: absolute;\n    top: 20px;\n    right: 30px;\n    transition: all 200ms;\n    font-size: 30px;\n    font-weight: bold;\n    text-decoration: none;\n    color: #333;\n  }\n  .popup .close:hover {\n    cursor: pointer;\n    color: #000;\n  }\n  .popup .content {\n    max-height: 30%;\n    overflow: auto;\n  }\n  \n  @media screen and (max-width: 700px) {\n    .popup {\n      width: 70%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
