import axios, { AxiosInstance } from "axios";

const envIsLocal = false; // TODO: change to false when pushing

const localUrl = "http://localhost:8080/gsp-account-adapter-svc";
const devUrl = "https://adapters.dev.bnymellon.net/gsp-account-adapter-svc";

const apiClient: AxiosInstance = axios.create({
    baseURL: envIsLocal ? localUrl : devUrl,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
    withCredentials: false,
});

export default apiClient;