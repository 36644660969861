import App from "./App";


export default function Root() {
  return (
    <section>
      <App />
    </section>
  );
}
