// CustodyAccountService.tsx
// @author Diego Jurado Gerardino (XFMH5JR)
import { CustodyResponse } from "../types/CustodyResponse";
import { CustodyAccount } from "../types/CustodyAccount";
import apiClient from "../types/ApiClient";


export const getAccount = async (accountNumber: string): Promise<CustodyResponse> => {
    try {
        const response = await apiClient.get(`accounts/${accountNumber}`);
        return response.data;
    } catch (error) {
        return error.response.data
    }
};

export const createAccount = async (custodyAccount: CustodyAccount): Promise<CustodyResponse> => {
    try {
        const response = await apiClient.post(`/accounts`, custodyAccount);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

// NOTE: this endpoint is not implemented in gsp-account-adapter-svc (Oct. 23, 2024)
export const updateAccount = async (accountNumber: string, custodyAccount: CustodyAccount): Promise<CustodyResponse> => {
    try {
        const response = await apiClient.put(`accounts/${accountNumber}`, custodyAccount);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};